.global_chart_filter_multi_select {
    font-size: 10px !important;
    width: 220px !important;
    border: none !important;
    align-items: center !important;
    color: black !important;
}

.global_chart_filter_multi_select .dropdown-heading {
    height: 30px !important;
}